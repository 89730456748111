<template>
    <div class="admin-content">
        <h3 v-text="state.defaults.BoardName"></h3>
        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button type="button" class="btn-box"
                        v-on:click="methods.getPersonalInfoConsignmentItems">조회</button>
                    <button type="button" class="btn-box green"
                        v-on:click="{ state.dialog.isOpen = true; state.dialog.isNew = true; }">신규</button>
                </div>
            </div>
        </div>
        <div class="total-box">
            <p>Total <strong>{{ state.dataTable.personalInfoConsignmentItems.length }}</strong></p>
        </div>
        <DataTable
            class="p-datatable-sm" 
            columnResizeMode="fit"
            scrollHeight="497px"
            :value="state.dataTable.personalInfoConsignmentItems" 
            :resizableColumns="true"
            :rowHover="true"
            @row-click="eventHandler.dataTable.onRowClick">

            <template #empty>
                데이터가 없습니다.
            </template>

            <Column field="Order_int" header="번호" :sortable="true" style="width:80px; text-align:right;" headerClass="tc"/>
            <Column field="P_Company" header="위탁받은자" :sortable="true" headerClass="tc"></Column>
            <Column field="P_Purpose" header="위탁목록" :sortable="true" headerClass="tc"></Column>
            <Column field="P_Contents" header="위탁내용" :sortable="true" headerClass="tc"></Column>
            <Column field="P_Date" header="이용 및 보유기간" :sortable="true" headerClass="tc"></Column>
        </DataTable>

        <!-- [fix-me] 팝업 디자인 없음 -->
        <Dialog 
            :visible="state.dialog.isOpen" 
            :modal="true" :style="{ width: '50vw'}" 
            @show="eventHandler.dialog.show"
            @hide="eventHandler.dialog.hide" 
            @close="state.dialog.isOpen = false"
            @update:visible="state.dialog.isOpen = false">

            <template #header>
                <h3>{{ state.dialog.header }}</h3>
            </template>

            <div class="card">
                <div>
                    <label for="basic" class="col-12 mb-2 md:col-2 md:mb-0">위탁받은자</label>
                    <div class="col">
                        <InputText type="text" class="w-full" v-model="state.dataTable.rowDataModel.P_Company" />
                    </div>
                </div>
                <div>
                    <label for="basic" class="col-12 mb-2 md:col-2 md:mb-0">위탁목적</label>
                    <div class="col">
                        <InputText type="text" class="w-full" v-model="state.dataTable.rowDataModel.P_Purpose" />
                    </div>
                </div>
                <div>
                    <label for="basic" class="col-12 mb-2 md:col-2 md:mb-0">위탁내용</label>
                    <div class="col">
                        <Textarea type="text" rows="5" class="w-full" :autoResize="true"
                            v-model="state.dataTable.rowDataModel.P_Contents" />
                    </div>
                </div>
                <div>
                    <label for="basic" class="col-12 mb-2 md:col-2 md:mb-0">이용 및 보유기간</label>
                    <div class="col">
                        <Textarea type="text" rows="5" class="w-full" :autoResize="true"
                            v-model="state.dataTable.rowDataModel.P_Date" />
                    </div>
                </div>
            </div>
            <br>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button v-show="state.dialog.isNew" class="btn-box gray" @click="methods.addPersonalInfoConsignment">생성</button>
                        <button v-show="!state.dialog.isNew" class="btn-box gray" @click="methods.editPersonalInfoConsignment">수정</button>
                        <button v-show="!state.dialog.isNew" :visible="!state.dialog.isNew" class="btn-box red" @click="methods.removePersonalInfoConsignment">삭제</button>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { notice } from '@/modules/site/PCM_SM0810R.js';
import { personalInfoConsignment } from '@/modules/site/PCM_SM0813R.js';
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
import alertManager from '@/utils/alert-confirm-manager';


export default {
    components: {},
    setup() {
        const vrt = useRoute();
        const store = useStore();
        const state = reactive({
            defaults: {},
            // 데이터 테이블 state
            dataTable: {
                // 개인정보위탁현황 목록
                personalInfoConsignmentItems: [],
                // 행 데이터 모델
                rowDataModel: new Object(),
            },
            dialog: {
                // 다이얼로그 헤더 텍스트
                header: "개인정보 위탁 현황",
                // 다이얼로그 오픈여부
                isOpen: false,
                // 신규 여부
                isNew: false,
            },
        });
        const methods = {
            // 목록 가져오기
            getPersonalInfoConsignmentItems: () => {
                store.commit("setLoadingStatus", true);

                // 개인정보위탁현황 목록 가져오기1
                personalInfoConsignment.getPersonalInfoConsignmentItems().then(personalInfoConsignmentItems => {
                    // 개인정보위탁현황 목록 설정
                    state.dataTable.personalInfoConsignmentItems = personalInfoConsignmentItems;
                }).finally(() => {
                    store.commit("setLoadingStatus", false);
                });
            },

            // 등록
            addPersonalInfoConsignment: () => {
                
                store.commit("setLoadingStatus", true);

                personalInfoConsignment.addPersonalInfoConsignment(state.dataTable.rowDataModel).then((response) => {

                    if(response.data.success) {

                        alertManager.toast.add();

                        // 목록 재조회
                        methods.getPersonalInfoConsignmentItems();
                        
                    }
                }).finally(() => {

                    store.commit("setLoadingStatus", false);

                    state.dialog.isOpen = false;
                });
            },

            // 수정
            editPersonalInfoConsignment: () => {
                store.commit("setLoadingStatus", true);

                personalInfoConsignment.editPersonalInfoConsignment(state.dataTable.rowDataModel).then((response) => {

                    if(response.data.success) {

                        alertManager.toast.save();

                        // 목록 재조회
                        methods.getPersonalInfoConsignmentItems();
                        
                    }
                }).finally(() => {

                    store.commit("setLoadingStatus", false);

                    state.dialog.isOpen = false;
                });
            },

            // 삭제
            removePersonalInfoConsignment: () => {
                store.commit("setLoadingStatus", true);

                personalInfoConsignment.removePersonalInfoConsignment(state.dataTable.rowDataModel).then((response) => {
                    
                    if(response.data.success) {

                        alertManager.toast.remove();

                        // 목록 재조회
                        methods.getPersonalInfoConsignmentItems();
                        
                    
                    }
                }).finally(() => {

                    store.commit("setLoadingStatus", false);

                    state.dialog.isOpen = false;
                });
            },
        };
        const eventHandler = {
            // 데이터 테이블 관련 이벤트 핸들러
            dataTable: {
                // 행 클릭 이벤트 처리
                onRowClick: event => {
                    // 행 데이터 모델 설정
                    state.dataTable.rowDataModel = Object.assign({}, event.data);

                    // 팝업 오픈
                    state.dialog.isOpen = true;
                },
            },
            // 다이얼로그 관련 이벤트 핸들러
            dialog: {
                // show 이벤트 처리
                show: () => {
                },
                // hide 이벤트 처리
                hide: () => {
                    //const aaa = []    // 이건 뭐냐??
                    //if (!aaa.length)  // 이건 뭐냐??

                    // 행 데이터모델 초기화
                    state.dataTable.rowDataModel = new Object();
                    state.dialog.isNew = false;
                },
            },
        };
        onMounted(() => {
            notice.getBoardInfo(vrt.query.boardcategory).then((res) => {
                if (res) {
                    state.defaults = res;
                    methods.getPersonalInfoConsignmentItems();
                } else {
                    alertManager.alert.warning("사용할 수 없는 게시판입니다.").then((x) => {
                        if (x) router.push({ name: "Home" });
                    });
                }
            });
        });

        return { state, personalInfoConsignment, methods, eventHandler };
    }
}
</script>