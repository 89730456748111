import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 개인정보위탁현황 화면 관련 기능 클래스
 */
class PersonalInfoConsignment {

    /**
     * 생성자
     */
    constructor() {

        // 시스템 코드
        this.systemCode = process.env.VUE_APP_SYSTEM_CODE;
    }

    /**
     * 목록 가져오기 
     * @returns 
     */
    getPersonalInfoConsignmentItems() {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.PrivacyBiz';
            request.methodId = 'GetPrivacyListInfo';

            service.execute(request).then(response => {
                
                const rows = response.data.result.value.Table.rows;
                resolve(rows);
            }).catch(error => reject(error));
        });
    }
    
    /**
     * 추가
     * @param {*} personalInfoConsignmentInfo 
     */
    addPersonalInfoConsignment(personalInfoConsignmentInfo) {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.PrivacyBiz';
            request.methodId = 'SavePrivacy';

            // 시스템코드 파라미터 추가
            personalInfoConsignmentInfo.Systcode = this.systemCode;
            request.parameters = personalInfoConsignmentInfo;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * 수정
     * @param {*} personalInfoConsignmentInfo 
     */
    editPersonalInfoConsignment(personalInfoConsignmentInfo) {
        
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.PrivacyBiz';
            request.methodId = 'UpdatePrivacy';

            request.parameters = personalInfoConsignmentInfo;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * 삭제
     * @param {*} personalInfoConsignmentInfo 
     */
    removePersonalInfoConsignment(personalInfoConsignmentInfo) {
        
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.PrivacyBiz';
            request.methodId = 'DeletePrivacyInfo';
            request.parameters = personalInfoConsignmentInfo;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }
}

export const personalInfoConsignment = new PersonalInfoConsignment();